import React from 'react';
import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby'; 
import { Modal, Button } from 'antd'; 
import SiteLayout from '@layouts/site-layout';
 

export default class InfoPage extends React.Component {

   

    render() {
        return (
            <SiteLayout> 
                <div className="top_margin_comn">
                     <div className="thank_page">
                        <h3>Oops!</h3>
                        <p> Your payment was unsuccessful. Please try again or contact our support team for any assistance.</p>
                        <div className="smile_img"><img src="img/sad.png" alt="sad" /></div>
                            <div className="footer_mess"> 
                                <p>For any further queries or assistance, reach us at (18002665007) / (support@stepapp.ai)</p>
                            </div>
                     </div>
                </div>    
            </SiteLayout>
        )
    }
}

 